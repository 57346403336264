import 'check-prop-types';
import Sentry from '@dbh/sentry';

/**
 * Validates `source` with `prop-types`.
 * @param {any} source .
 * @param {Object} sourcePropTypes .
 * @param {string} sourceName .
 * @throws If `sourcePropTypes` is not an `Object`, or if the props found
 * in `source` are not valid.
 */const conformsTo=()=>{};var conformsTo$1 = conformsTo;

/**
 * Logs the given error to `sentry` and in the console. In production (`www.`)
 * it does not log in the console, but just sends the error to `sentry`.
 * @param {Error} error .
 */const logPropTypeError=a=>{console.error("Internal validation error."),Sentry.captureException(a);};var logPropTypeError$1 = logPropTypeError;

/**
 * Validates `source` with `prop-types`.
 * @param {any} source .
 * @param {Object} sourcePropTypes .
 * @param {string} sourceName .
 * @throws If `sourcePropTypes` is not an `Object`, or if `source` contains
 * props not defined in `sourcePropTypes` and as such unexpected. Or if the
 * expected props found in `source` are not valid.
 */const conformsToExact=()=>{};var conformsToExact$1 = conformsToExact;

// sometimes breaks the navigation. Maybe what has been changed in the API is a
// property that is not required in the current saga, so interrupting the flow by
// throwing may be too strict. It is NOT recommended to ignore these errors though,
// a name change or the removal of a property from a `JSON` can break other parts
// or pages or logic of the Front End, even if it's not visible in the current
// page/view/saga you are working on.
const conformsToExactWithoutThrow=function(){try{return conformsToExact$1(...arguments),!0}catch(a){return logPropTypeError$1(a),!1}};var conformsToExactWithoutThrow$1 = conformsToExactWithoutThrow;

export { conformsTo$1 as conformsTo, conformsToExact$1 as conformsToExact, conformsToExactWithoutThrow$1 as conformsToExactWithoutThrow };
