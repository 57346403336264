import 'invariant';
import { PAGE_TYPES } from '@dbh/admindev-constants';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import '@dbh/admindev-types';
import 'react-immutable-proptypes';
import '@dbh/search-context-types';
import '@dbh/generic-types';

const REDUX_AND_SAGA_KEY="webpages";// `redux` constants: `@dbh/webpages-redux`.
const LOAD_WEBPAGES_INVOKED="@dbh/webpages-redux/LOAD_WEBPAGES_INVOKED";const LOAD_WEBPAGES_SUCCESS="@dbh/webpages-redux/LOAD_WEBPAGES_SUCCESS";const LOAD_WEBPAGES_ERROR="@dbh/webpages-redux/LOAD_WEBPAGES_ERROR";// `redux` constants: `webpagesRoutes`.
const LOAD_WEBPAGES_ROUTES_SUCCESS="@dbh/webpages-redux/LOAD_WEBPAGES_ROUTES_SUCCESS";const LOAD_WEBPAGES_ROUTES_ERROR="@dbh/webpages-redux/LOAD_WEBPAGES_ROUTES_ERROR";const LOAD_WEBPAGES_ROUTES_INVOKED="@dbh/webpages-redux/LOAD_WEBPAGES_ROUTES_INVOKED";const NAVIGATED_TO_WEBPAGES_ROUTE="@dbh/webpages-redux/NAVIGATED_TO_WEBPAGES_ROUTE";const NAVIGATED_TO_WEBPAGES_ROUTE_FAILED="@dbh/webpages-redux/NAVIGATED_TO_WEBPAGES_ROUTE_FAILED";const NAVIGATED_TO_WEBPAGES_ROUTE_WITH_SEARCH_CONTEXT="@dbh/webpages-redux/NAVIGATED_TO_WEBPAGES_ROUTE_WITH_SEARCH_CONTEXT";// Constants.
const webpagesRoutesFields=["url","id","templateCode","reference"];const webpagesRoutesTemplateCodes=[PAGE_TYPES.ContentPage,PAGE_TYPES.BookingFormPage,PAGE_TYPES.CancelReservationPage,PAGE_TYPES.ConfirmReservationPage,PAGE_TYPES.ViewReservationPage,PAGE_TYPES.ViewFirstReservationPage,PAGE_TYPES.PostStayRatingPage,PAGE_TYPES.SearchResultsPage,PAGE_TYPES.AllCountriesPage,PAGE_TYPES.NotFoundPage,PAGE_TYPES.TopMenuHashPage,PAGE_TYPES.HashPage,PAGE_TYPES.HomePage,PAGE_TYPES.ResetPasswordPage,PAGE_TYPES.UserReservationsPage,PAGE_TYPES.ShoppingCartPage,PAGE_TYPES.ViewInvoicesPaymentPage,PAGE_TYPES.InvoicesPaymentFormPage];const TEMPLATE_CODES_TO_STATIC_RENDER=[PAGE_TYPES.CategoryCityPage];const DEFAULT_WEBPAGES_ROUTES_QUERY_PARAMETERS=Object.freeze({templateCode:webpagesRoutesTemplateCodes.join(","),_fields:webpagesRoutesFields.join(","),_makeUrlRelative:!0});const HERO_SECTION_KEYS=["imageUrl","mainTitle","subTitle","imageMobileUrl","mainTitleHtmlTag","subTitleHtmlTag"];const DBH_STATIC_CATEGORY_CITY_PAGE_FORCE_DYNAMIC_QUERY_PARAM="dbh-force-dynamic-lpcc";

/**
 * @typedef {import('@dbh/admindev-types').AdminPageReference} AdminPageReference
 *//**
 * Load the `webpages`; this event starts the `API` request saga.
 * @param {Object?} options .
 * @param {string?} options.locale The current locale.
 * @param {string?} options.country The current country.
 * @param {boolean?} options.forceFetch Indicator to fetch the web page even if it
 * was cached.
 * @return {Object} .
 */const loadWebpagesInvoked=withConformsTo("loadWebpagesInvoked",[],a=>{const{locale:b,country:c,forceFetch:d}=a||{};return {type:LOAD_WEBPAGES_INVOKED,locale:b,country:c,forceFetch:d}});var loadWebpagesInvoked$1 = loadWebpagesInvoked;

/**
 * @typedef {import('@dbh/admindev-types').AdminPageReference} AdminPageReference
 *//**
 * Dispatched to load the `webpages` for the current page, making the `API`
 * requests even if the data is already cached in the redux store.
 * @param {Object?} options .
 * @param {string?} options.locale .
 * @param {string?} options.country .
 * @return {Object} .
 */const loadWebpagesAndIgnoreCacheInvoked=withConformsTo("loadWebpagesAndIgnoreCacheInvoked",[],a=>{const{locale:b,country:c}=a||{};return loadWebpagesInvoked$1({locale:b,country:c,forceFetch:!0})});var loadWebpagesAndIgnoreCacheInvoked$1 = loadWebpagesAndIgnoreCacheInvoked;

/**
 * Invokes the loading of the `webpagesRoutes`; starting the `API` request saga.
 * @param {Object} options .
 * @param {string?} options.country Only pass if you want the `URL` in
 * a different country compared to the one the user is currently in. If you pass
 * this you must also pass `locale`.
 * @param {string?} options.locale Only pass if you want the `URL` in
 * a different culture compared to the one the user is currently in. If you pass
 * this you must also pass `country`.
 * @param {boolean} options.forceFetch Indicator to fetch the web pages routes
 * even if it was cached.
 * @return {Object} .
 */const loadWebpagesRoutesInvoked=withConformsTo("loadWebpagesRoutesInvoked",[],a=>{const{country:b,locale:c,forceFetch:d}=a||{};return {type:LOAD_WEBPAGES_ROUTES_INVOKED,payload:{country:b,locale:c,forceFetch:d}}});var loadWebpagesRoutesInvoked$1 = loadWebpagesRoutesInvoked;

/**
 * Dispatched to load the `webpages` routes for the current page, making the `API`
 * requests even if the data is already cached in the redux store.
 * @param {Object?} options .
 * @param {string?} options.locale .
 * @param {string?} options.country .
 * @return {Object} .
 */const loadWebpagesRoutesAndIgnoreCacheInvoked=withConformsTo("loadWebpagesRoutesAndIgnoreCacheInvoked",[],a=>{const{locale:b,country:c}=a||{};return loadWebpagesRoutesInvoked$1({country:c,locale:b,forceFetch:!0})});var loadWebpagesRoutesAndIgnoreCacheInvoked$1 = loadWebpagesRoutesAndIgnoreCacheInvoked;

/**
 * @typedef {import('@dbh/admindev-types').AdminPageReference} AdminPageReference
 *//**
 * @typedef {Object} LocationChangedToWebpagesRouteOptions
 * @property {AdminPageReference?} adminPageReference An option to filter webpages
 * routes. You can set it in the `admindev`, in the "Web Pages".
 * @property {Object} urlOptions To build the `URL`.
 *//**
 * Navigates to a `webpagesRoute`, by default in the current country and culture.
 * A) Preferably use "normal" links printed in the `HTML`.
 * B) If you really cannot use a link, then you can use this function.
 * C) You can use `options.routeParameters.country/culture` if you want to navigate
 * to the route in a different country and culture compared to the current. Note
 * that if the `webpagesRoutes` are not in the store for that combination, the
 * saga will first fetch them from the `API`, otherwise it can't navigate to a
 * not known `URL`.
 * @param {Object} options .
 * @param {string?} options.pageType .
 * @param {LocationChangedToWebpagesRouteOptions?} options.options .
 * @return {Object} .
 */const locationChangedToWebpagesRoute=withConformsTo("locationChangedToWebpagesRoute",[],a=>{let{pageType:b,options:c}=a;return {type:NAVIGATED_TO_WEBPAGES_ROUTE,payload:{pageType:b,options:c}}});var locationChangedToWebpagesRoute$1 = locationChangedToWebpagesRoute;

/**
 * Navigation to a `webpagesRoute` failed; either the parameters were wrong or
 * the `admindev` "Web Pages" are not well configured for the requested or
 * current country and culture combination and page.
 * @param {Object} param .
 * @param {string} param.pageType .
 * @param {Object?} param.error .
 * @return {Object} .
 */const locationChangedToWebpagesRouteFailed=a=>{let{pageType:b,error:c}=a;return {type:NAVIGATED_TO_WEBPAGES_ROUTE_FAILED,payload:{pageType:b,error:c},error:!0}};var locationChangedToWebpagesRouteFailed$1 = locationChangedToWebpagesRouteFailed;

/**
 * @typedef {import('@dbh/admindev-types').AdminPageReference} AdminPageReference
 *//**
 * Invokes the navigation to a `webpagesRoute` (a "Web Page" in the `admindev`),
 * with the (required) passed `searchContext`, that will be placed in the query
 * parameters.
 * @param {Object} param .
 * @param {Immutable.Map} param.searchContext .
 * @param {Immutable.Map?} param.tracking .
 * @param {string} param.pageType .
 * @param {string?} param.country Pass only if you want to navigate to a site
 * in a different country compared to the one in the current `URL`. If you pass
 * this, you must also pass `locale`.
 * @param {string?} param.locale Pass only if you want to navigate to a site
 * in a different culture compared to the one in the current `URL`. If you pass
 * this, you must also pass `country`.
 * @param {AdminPageReference?} param.adminPageReference .
 * @return {Object} .
 */const locationChangedToWebpagesRouteWithSearchContext=withConformsTo("locationChangedToWebpagesRouteWithSearchContext",[],a=>{let{searchContext:b,pageType:c,country:d,locale:e,tracking:f,adminPageReference:g}=a;return {type:NAVIGATED_TO_WEBPAGES_ROUTE_WITH_SEARCH_CONTEXT,payload:{searchContext:b,pageType:c,country:d,locale:e,tracking:f,adminPageReference:g}}});var locationChangedToWebpagesRouteWithSearchContext$1 = locationChangedToWebpagesRouteWithSearchContext;

/**
 * Dispatched when the `webpages` are loaded by the request saga.
 * @param {Object} options .
 * @param {Immutable.Map} options.requestAttributes .
 * @param {Immutable.Map} options.webpages .
 * @param {Immutable.Map?} options.userAreaData .
 * @param {string} options.locale .
 * @param {string} options.country .
 * @param {string} options.pathname .
 * @param {string?} options.templateCode Not required when the event is called
 * with a redirect (in that case `options.webpages.redirectTo` is truthy).
 * @param {boolean?} options.fromCache .
 * @param {boolean?} options.isUserLoaded .
 * @return {Object} .
 */const webpagesLoaded=withConformsTo("webpagesLoaded",[],a=>{let{requestAttributes:b,webpages:c,userAreaData:d,locale:e,country:f,pathname:g,templateCode:h,fromCache:i,isUserLoaded:j}=a;return {type:LOAD_WEBPAGES_SUCCESS,payload:{requestAttributes:b,webpages:c,userAreaData:d,locale:e,country:f,pathname:g,templateCode:h,fromCache:i,isUserLoaded:j}}});var webpagesLoaded$1 = webpagesLoaded;

/**
 * Dispatched when loading the `webpages` fails.
 * @param {Object} param .
 * @param {string} param.country .
 * @param {string} param.locale .
 * @param {string} param.pathname .
 * @param {Error} param.error .
 * @return {Object} .
 */const webpagesLoadingError=a=>{let{country:b,locale:c,pathname:d,error:e}=a;return {type:LOAD_WEBPAGES_ERROR,payload:{country:b,locale:c,pathname:d,error:e}}};var webpagesLoadingError$1 = webpagesLoadingError;

/**
 * Returns an event that signals the failure of loading the `webpagesRoutes`.
 * @param {Object} obj .
 * @param {Object?} obj.error .
 * @param {string} obj.country The country of the `API` request.
 * @param {string} obj.locale The locale of the `API` request.
 * @return {Object} .
 */const webpagesRoutesError=a=>{let{error:b,country:c,locale:d}=a;return {type:LOAD_WEBPAGES_ROUTES_ERROR,payload:{error:b,country:c,locale:d},error:!0}};var webpagesRoutesError$1 = webpagesRoutesError;

const webpagesRoutesLoaded=withConformsTo("webpagesRoutesLoaded",[],a=>{let{webpagesRoutes:b,country:c,locale:d,requestAttributes:e,fromCache:f}=a;return {type:LOAD_WEBPAGES_ROUTES_SUCCESS,payload:{webpagesRoutes:b,country:c,locale:d,fromCache:f,requestAttributes:e}}});/**
 * Returns an event to invoke the loading of the `webpagesRoutes` (`URLs` of the
 * "Web Pages" in the `admindev`).
 * @param {Object} options .
 * @param {Object[]|Immutable.List<Immutable.Map>} options.webpagesRoutes .
 * @param {string} options.country The country of `param.webpagesRoutes`.
 * @param {string} options.locale The locale of `param.webpagesRoutes`.
 * @param {boolean?} options.fromCache `true` if the saga did not need to make an
 * `API` request, since the data was already "cached" in the `redux` store.
 * @param {Immutable.Map?} options.requestAttributes Not required when `fromCache`
 * is `true`.
 * @return {Object} .
 */var webpagesRoutesLoaded$1 = webpagesRoutesLoaded;

export { DBH_STATIC_CATEGORY_CITY_PAGE_FORCE_DYNAMIC_QUERY_PARAM, DEFAULT_WEBPAGES_ROUTES_QUERY_PARAMETERS, HERO_SECTION_KEYS, LOAD_WEBPAGES_ERROR, LOAD_WEBPAGES_INVOKED, LOAD_WEBPAGES_ROUTES_ERROR, LOAD_WEBPAGES_ROUTES_INVOKED, LOAD_WEBPAGES_ROUTES_SUCCESS, LOAD_WEBPAGES_SUCCESS, NAVIGATED_TO_WEBPAGES_ROUTE, NAVIGATED_TO_WEBPAGES_ROUTE_FAILED, NAVIGATED_TO_WEBPAGES_ROUTE_WITH_SEARCH_CONTEXT, REDUX_AND_SAGA_KEY, TEMPLATE_CODES_TO_STATIC_RENDER, loadWebpagesAndIgnoreCacheInvoked$1 as loadWebpagesAndIgnoreCacheInvoked, loadWebpagesInvoked$1 as loadWebpagesInvoked, loadWebpagesRoutesAndIgnoreCacheInvoked$1 as loadWebpagesRoutesAndIgnoreCacheInvoked, loadWebpagesRoutesInvoked$1 as loadWebpagesRoutesInvoked, locationChangedToWebpagesRoute$1 as locationChangedToWebpagesRoute, locationChangedToWebpagesRouteFailed$1 as locationChangedToWebpagesRouteFailed, locationChangedToWebpagesRouteWithSearchContext$1 as locationChangedToWebpagesRouteWithSearchContext, webpagesLoaded$1 as webpagesLoaded, webpagesLoadingError$1 as webpagesLoadingError, webpagesRoutesError$1 as webpagesRoutesError, webpagesRoutesFields, webpagesRoutesLoaded$1 as webpagesRoutesLoaded, webpagesRoutesTemplateCodes };
